<template>
  <section>
    <b-card no-body>
      <app-data-table
        ref="refProductsListTable"
        :actions-table="{
          showEdit: $can('SERVICE_EDIT'),
          showShow: $can('OPERATOR_SERVICE_VIEW') || $can('SERVICE_VIEW'),
          showDelete: $can('SERVICE_DELETE'),
        }"
        :bottom-table="{ totalCount: totalProducts }"
        :fields="tableColumns"
        :items="fetchServices"
        :small="$store.getters['app/xlAndDown']"
        :top-table="{
          showAdd: $can('SERVICE_EDIT'),
          showSearch: true,
          searchFilterOptions,
        }"
        table-name="product-list"
        @delete="deleteService($event.item.id)"
        @edit="
          $router.push({
            name: 'productEdit',
            params: {
              service_id: $event.item.id,
              typology: kebabCase($event.item.typology),
              tab: 'main-information',
            },
          })
        "
        @show="redirectToShow($event)"
        @table-add="$router.push({ name: 'serviceAdd' })"
      >
        <!-- Head: status -->
        <template #head(status)="{ label }">
          <div class="pl-75">{{ label }}</div>
        </template>

        <!-- Cell: Id -->
        <template #cell(id)="{ item }">
          <b-link
            :disabled="!$can('SERVICE_EDIT')"
            :to="{
              name: 'productEdit',
              params: {
                service_id: item.id,
                typology: kebabCase(item.typology),
                tab: 'main-information',
              },
            }"
            class="font-weight-bold"
          >
            {{ item.baseReference }}
          </b-link>
        </template>

        <!-- Cell: name -->
        <template #cell(name)="{ item }">
          <div class="text-truncate">
            <span>{{ item.name | trans }}</span>
          </div>
        </template>

        <!-- Cell: organization -->
        <template #cell(organization)="{ item }">
          <div class="text-truncate">
            <span>{{ item.organization.legalName }}</span>
          </div>
        </template>

        <!-- Cell: mainCategoryName -->
        <template #cell(mainCategoryName)="{ item }">
          <div class="text-truncate">
            <span>{{ item.mainCategory.name | trans }}</span>
          </div>
        </template>

        <!-- Cell: status -->
        <template #cell(status)="{ item }">
          <b-badge :variant="`light-${resolveStatusVariant(item.state)}`" class="text-capitalize" pill>
            {{ item.state | enumTranslate('product_state') }}
          </b-badge>
        </template>

        <!-- Cell: visible -->
        <template #cell(visible)="{ item }">
          <b-form-checkbox :checked="item.visible" disabled inline name="check-button" switch />
        </template>

        <!-- Cell: typology -->
        <template #cell(type)="{ item }">
          <div class="text-truncate">
            <span>{{ item.typology | enumTranslate('product_typology') }}</span>
          </div>
        </template>

        <!-- Cell: onQuote -->
        <template #cell(onQuote)="{ item }">
          <b-form-checkbox v-if="item.mainVariant" :checked="item.mainVariant.onQuote" disabled inline name="check-button" switch />
        </template>
      </app-data-table>
    </b-card>
  </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'

import { kebabCase } from 'lodash'

import UIHelper from '@/helpers/ui'
import { deleteProductRequest, fetchProductsRequest } from '@/request/globalApi/requests/productRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default defineComponent({
  name: 'ProductList',

  components: {
    AppDataTable,
  },

  mixins: [UIHelper],

  setup(_props, ctx) {
    const { $i18n } = ctx.root

    const refProductsListTable = ref(null)

    const tableColumns = [
      { key: 'id', label: $i18n.t('service.reference'), tdClass: 'py-1 py-xl-0', sortable: true },
      { key: 'name', label: $i18n.t('service.name'), sortable: true },
      { key: 'mainCategoryName', label: $i18n.t('service.category'), sortable: true },
      { key: 'status', label: $i18n.t('common.status'), sortable: true },
      { key: 'onQuote', label: $i18n.t('service.on_quote') },
      { key: 'visible', sortable: true },
      { key: 'type' },
    ]
    if (ctx.root.$can('OPERATOR_SERVICE_VIEW')) {
      tableColumns.splice(1, 0, { key: 'organization', label: $i18n.t('account.organization.title'), sortable: true })
    }

    const searchFilterOptions = [{ text: $i18n.tc('common.service', 2), value: 'productName' }]
    const totalProducts = ref(0)

    let currentProductsList = null
    const fetchServices = (btableMeta, callback) => {
      const params = {
        page: btableMeta.currentPage > 1 ? btableMeta.currentPage : null,
        numberOfItemsPerPage: btableMeta.perPage,
        searchQuery: btableMeta.filter,
        // Returned order = field.key + 'Order'
        idOrder: btableMeta.computedSort.idOrder,
        nameOrder: btableMeta.computedSort.nameOrder,
        stateOrder: btableMeta.computedSort.statusOrder,
        visibilityOrder: btableMeta.computedSort.visibleOrder,
        categoryOrder: btableMeta.computedSort.mainCategoryNameOrder,
      }

      currentProductsList = refProductsListTable.value.localItems

      fetchProductsRequest(params)
        .then(response => {
          const { products, totalItems } = response.data
          currentProductsList = products
          totalProducts.value = totalItems
        })
        .finally(() => {
          callback(currentProductsList)
        })
    }

    const deleteService = id => {
      deleteProductRequest(id).then(isConfirm => {
        if (!isConfirm) return
        currentProductsList = currentProductsList.filter(product => product.id !== id)
        refProductsListTable.value.refresh()
      })
    }

    const redirectToShow = event => {
      if (ctx.root.$can('SERVICE_VIEW')) {
        ctx.root.$router.push({
          name: 'productShow',
          params: {
            service_id: event.item.id,
            typology: kebabCase(event.item.typology),
            tab: 'main-information',
          },
        })
      } else if (ctx.root.$can('OPERATOR_SERVICE_VIEW')) {
        ctx.root.$router.push({
          name: 'operatorProductShow',
          params: {
            service_id: event.item.id,
            typology: kebabCase(event.item.typology),
            tab: 'main-information',
          },
        })
      }
    }

    return {
      refProductsListTable,
      tableColumns,
      searchFilterOptions,
      totalProducts,
      fetchServices,
      deleteService,
      kebabCase,
      redirectToShow,
    }
  },
})
</script>
